import { template as template_2c33570fdd394a5c926298037ea64980 } from "@ember/template-compiler";
const FKControlMenuContainer = template_2c33570fdd394a5c926298037ea64980(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
