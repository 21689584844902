import { template as template_224432cf9bf6461db063a4a29f1d4150 } from "@ember/template-compiler";
const FKLabel = template_224432cf9bf6461db063a4a29f1d4150(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
