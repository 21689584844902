import { template as template_be50b3bfceb94be6b60f7ccbf6632691 } from "@ember/template-compiler";
const WelcomeHeader = template_be50b3bfceb94be6b60f7ccbf6632691(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
